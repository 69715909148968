:root {
  --background: white;
}
@font-face {
  font-family: 'tktype';
  font-style: normal;
  font-weight: 600;
  src:url('./assets/fonts/tktypemedium.woff') format('woff');
}
@font-face {
  font-family: 'tktype';
  font-style: normal;
  font-weight: 500;
  src:url('./assets/fonts/tktypemedium.woff') format('woff');
}
@font-face {
  font-family: 'tktype';
  font-style: normal;
  font-weight: 400; 
  src: url('./assets/fonts/tktyperegular.woff') format('woff');
}
body {
  margin: 0;
  font-family: 'tktype,Calibri,Arial,Helvetica,sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #F6F8FA;
  height: 100vh;
}
#root {
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1{
  font-size: 36px !important;
  line-height: 49px;

}
.Oval1,
.Oval2,
.Oval3 {
  animation-name: load;
  animation-duration: 0.9s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  opacity: 0;
}
.Oval2 {
  animation-delay: 0.3s;
}
.Oval1 {
  animation-delay: 0.6s;
}


@keyframes load{
  0%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0.5;
  }
}


@media screen and (max-width : 900) {
  h1{
    font-size: 32px !important;
    line-height: 38px;
  
  }
}
